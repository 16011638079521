import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import Helmet from "react-helmet";
import { Header } from "../components/03_organisms/header";
import { Footer } from "../components/03_organisms/footer";
import { Sidebar } from "../components/03_organisms/sidebar";
import { Bio } from "../components/03_organisms/bio";
import { FirstDescItem } from "../components/02_molecules/firstDescItem";
import { DescItem } from "../components/02_molecules/descItem";
import { AdsenseComponent } from "../components/02_molecules/adsense";
import { Tags } from "../components/02_molecules/tags";
import ogPic from "../images/ogp.png";

const Home = ({ data }) => {
  let first = data.allMarkdownRemark.edges[0];
  let list = data.allMarkdownRemark.edges.slice(1);
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={data.site.siteMetadata.title}
        meta={[
          { name: "description", content: data.site.siteMetadata.description },
          { name: "keywords", content: data.site.siteMetadata.keywords },
          { property: "og:title", content: data.site.siteMetadata.title },
          {
            property: "og:description",
            content: data.site.siteMetadata.description,
          },
          { property: "og:url", content: data.site.siteMetadata.siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary_large_image" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + ogPic,
          },
          { httpEquiv: "expires", content: "43200" },
          //{ property: 'og:image', content: mushiIcon },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <div className="mainWrapper">
        <Header isHome={true} />
        <div id="main-contents">
          <FirstDescItem
            post={first.node}
            href={first.node.fields.slug}
            excerpt={first.node.excerpt}
          />
          <AdsenseComponent
            client="ca-pub-8068277400499023"
            slot="7208126827"
          />
          <Bio />
          <div id="flex-root">
            {list.map(({ node }, index) => (
              <DescItem post={node} href={node.fields.slug} key={index} />
            ))}
          </div>
          <AdsenseComponent
            client="ca-pub-8068277400499023"
            slot="7208126827"
          />
        </div>
        <Sidebar />

        <Tags />
        <Footer />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 17
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { ne: "reliure" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            category
            tags
            ogpimage
          }
          fields {
            slug
          }
          excerpt(truncate: true)
        }
      }
    }
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;
export default Home;
